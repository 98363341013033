<template>
    <div class="layout-px-spacing">

        <div class="error404 text-center">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4 mr-auto mt-5 text-md-left text-center">
                        <router-link to="/" class="ml-md-5">
                            <img alt="image-404" src="@/assets/img/navbar/logo.png" class="theme-logo" />
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="container-fluid error-content">
                <div class="">
                    <h1 class="error-number">404</h1>
                    <p class="mini-text">Uuuups!</p>
                    <p class="error-text mb-4 mt-1">Stránka se nenašla!</p>
                    <a href="/" class="btn mt-5 btn-primary">Zpět</a>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import '../../assets/sass/errors/style-400.scss';
    export default {
        metaInfo: { title: '404 Stránka sa nenašla' },
        mounted() {
        },
        methods: {
        }
    };
</script>
